<template>
  <div class="relative h-full min-h-screen">
    <TheNavBar />
    <div class="p-10">
      <router-view />
    </div>

    <div class="pt-10"></div>
    <TheCat />
    <TheFooter />
  </div>
</template>

<script>
import TheNavBar from '@/components/TheNavBar';
import TheFooter from '@/components/gem-farm/TheFooter';
import TheCat from '@/components/gem-farm/TheCat';
export default {
  components: { TheFooter, TheNavBar },
};
</script>

<style>
* {
 font-family: 'Rajdhani', sans-serif;
 color:#222;
}
body {font-size:14px !important;}
h2 {color:#2d2d42;}
p {font-size:1.4em;}
.title {font-size:1.4em !important; margin-top:-1.9em!important;}
.title, .text-black {font-weight:700;}
.heading {font-size:6.25rem !important;}
.toggle__title {font-family: 'Rajdhani', sans-serif;}
.btn-solana {background:linear-gradient(rgb(96, 74, 229) 0%, rgb(129, 62, 238) 100%); color:white;}
.color-box {background:#3f4acd;
    position: relative;
    min-width: 0;
    min-height: 159px;
    word-wrap: break-word;
    padding: 1.35rem;
    flex: 1 1 auto;
    border-radius: 10px;
    transition: all .2s ease-in-out;
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(25px);
    border: 1px solid rgba(139 157 193 / 15%);
    box-shadow: 0 5px 15px 0 rgb(43 45 49 / 10%);}
input[type='radio']:checked + span {
  @apply text-black;
}
</style>
